.content-layout{
    background: linear-gradient(rgba(226,248,255, 0.5) , #fff),
    linear-gradient(to right, #d6e4ff, #f9f0ff);
    background-blend-mode : screen;
}

.login-shadow{
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.header{
    font-family: NeuePlak;
}